import styled from "styled-components";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { createContext } from "react";
import dataService from "../services/fetchData.service";
import { useNavigate } from "react-router-dom";
import AnimationComponent from "./Animation";
import LoaderGifImage from "../assets/circle-loader.gif"
import { useLocation } from "react-router-dom";


const ContainerPage = styled.div`
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    width: 100%;
    height:100vh;

`

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7%;
    height: 12%;
    @media (max-width: 720px) {

        width: 10%;
        height: 5%;
        
    }
`

const LoaderGif = styled.img`
    background: black;
    width: 100%;
    height: 100%;
    @media (max-width: 720px) {
        width: 60%;
        height: 60%;
    }
`

const Text = styled.p`
    display: none;
    @media (max-width: 720px) {
        display: flex;   
        z-index: 9999;
    }
`


export const paramContext = createContext("")

function Verifier() {
    const [isValid,setIsValid] = useState(false)
    const {parameter} = useParams<{parameter?: string}>();
    const [wallet, setWalletAddress] = useState<string>('')
    const [data, setData] = useState(undefined)

    const navigate = useNavigate()

    const location = useLocation()


    useEffect(() => {
        if(!parameter){
            setWalletAddress(location.state?.walletProps)
        }
    },[parameter])

    const handleGetData = async() => {
        const response = await dataService.fetchOwnersData(wallet!)
        if(!response) {
            alert("You are not a owner of this collection");
            window.open("https://mint.jokerclub.io", "_self")
        }
        setData(response)
    }

    useEffect(()=>{
        if(parameter) {
            console.log(parameter)
            setWalletAddress(parameter)
        }
        else {
            navigate('/connectwallet')
            
        }
        // if(!parameter) return alert("Wrong url")
    },[parameter])

    useEffect(() =>{
        if(wallet){
            console.log(wallet)
            handleGetData()
        }

    },[wallet])

    useEffect(() =>{
        if(data){
           console.log("Data: ",data)
           setIsValid(true)
        }

    },[data])
    



    return(
        <>
            {!isValid &&
            <>
            <ContainerPage>
                <LoaderContainer>
                    <LoaderGif src={LoaderGifImage}/>
                </LoaderContainer>
            </ContainerPage>
            </>
            }
            {isValid && 
            <>
            <AnimationComponent data={data!} />
            </>}
        </>
    )
}

export default Verifier;