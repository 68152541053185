import axios,{AxiosResponse} from 'axios'


const api = axios.create({
    baseURL: "https://backend-machine.herokuapp.com/data",
    headers : {"Content-Type" : "application/json", 'Access-Control-Allow-Origin': '*'}

})

const fetchOwnersData = async (wallet: string) => {
    try {
        const response : AxiosResponse = await api.post("/formatData",{
            wallet: wallet
        })
        if(!response) return null
        return response.data

    }
    catch(error) {
        console.log(error)
        Promise.reject(error)
        return null
    }
}


const dataService = {fetchOwnersData}

export default dataService;