import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import {Route, Routes} from 'react-router-dom'
import Verifier from './components/Verifier_Fetcher';
import ConnectWallet from './components/Connect_Wallet';



function App() {
  return (
    <Routes>
      <Route path="/:parameter?" element={<Verifier/>} />
      <Route path="/connectwallet" element={<ConnectWallet/>} />
    </Routes>
  );
}

export default App;
