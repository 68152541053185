import styled from "styled-components";
import React, { useRef, useEffect } from 'react';
import { useState } from "react";
import "./Iframe.css"

interface IframeComponentProps {
  src: string;
  onReady?: () => void;
  onReveal?: () => void;
  onMyWallet?: () => void;
  onShare?: () => void;
  data: any[];
}

const IframeComponent: React.FC<IframeComponentProps> = ({ src, onReady, onReveal, onMyWallet, onShare, data }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [currentIndex, setCurrentIndex] = useState(0)

  const [imageUrl, setImageUrl] = useState<string>('')

  useEffect(() => {
    console.log(currentIndex)
  },[currentIndex])

  useEffect(() => {
    if(data && currentIndex >= 0){
      const image = data[0].data.imageUrl
      setImageUrl(image)
      console.log(imageUrl)
      console.log("hereeeee",data[0].data.imageUrl)
    }

  },[data, currentIndex])


  useEffect(() =>{
    console.log(imageUrl)
  },[imageUrl])

 
  useEffect(() => {
    const handleIframeEvent = (event: MessageEvent) => {
      const message = event.data;

      switch (message.type) {
        case 'ready':
          if (onReady) {
            if (iframeRef.current) {
              const currentData = data[currentIndex]
              if(currentIndex < data.length) {
                const message = JSON.stringify(currentData)
                console.log(message)
                iframeRef.current.contentWindow?.postMessage(currentData, "*");
              }
            };
          }
          break;
        case 'action':
          if (message.action === 'reveal' && onReveal) {
            if (currentIndex < data.length - 1) {
              setCurrentIndex((prevIndex) => prevIndex + 1);
            }
          } else if (message.action === 'mywallet' && onMyWallet) {
            console.log('My Wallet button clicked.');
            window.location.href = 'https://opensea.io/fr/account';
          } else if (message.action === 'share' && onShare) {
            console.log('Share button clicked.');
            let text = ''
            if(data.length === 1) {
              text = encodeURIComponent(`Hey ! I just revealed my Joker NFT with an amazing slot machine. Cheers to the Joker Club ! @JokerClubSpirit #revealmyjoker`)
            }
            else{
              text = encodeURIComponent(`Hey ! I just revealed ${data.length} Jokers NFTs with an amazing slot machine. Cheers to the Joker Club ! @JokerClubSpirit #revealmyjoker`)
            }
            const url = encodeURIComponent(imageUrl);
            const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
        
            window.open(twitterUrl, '_blank', 'noopener noreferrer');
          }
          break;
        default:
          break;
      }
    };

    if (iframeRef.current) {
      window.addEventListener('message', handleIframeEvent);
    }

    return () => {
      window.removeEventListener('message', handleIframeEvent);
    };
  }, [currentIndex, imageUrl]);

  return (
    <iframe
      ref={iframeRef}
      src={src}
      style={{ width: '100%', height: '100%', background: 'black' }}
      title="Reveal"
      className="iframe-machine"
    />
  );
};

export default IframeComponent;
