import { useEffect, useState } from "react";
import styled from "styled-components";
import IframeComponent from "./Iframe";
import phoneGif from "../assets/turn-phone.gif"



const ContainerPage = styled.div`
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    width: 100%;
    height:100vh;

`

const Overlay = styled.div`
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position :absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
`

const ContainerIconTurn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
`


const TurnGif = styled.img`
    width: 100%;
    height: 100%;
`



interface AnimationComponentProps {
    data: any[];
}

const AnimationComponent: React.FC<AnimationComponentProps> = ({data}) => {
    const [isMobile, setIsMobile] = useState<boolean>(false)
    const [isLandscape, setIsLandscape] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0)
    

    const handleReady = () => {
        console.log('The game is ready.');
        console.log(data)
        const currentData = data[currentIndex]
        if(currentIndex < data.length) {
            const message = JSON.stringify(currentData)
            console.log(message)
            window.postMessage(message, '*')
            console.log('Mess posted')
        }
    };
    
    const handleReveal = () => {
        if (currentIndex < data.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };
    
    const handleMyWallet = () => {
        console.log('My Wallet button clicked.');
        window.location.href = 'https://opensea.io/fr/account';
    };
    
    const handleShare = () => {
        console.log('Share button clicked.');
        // const url = 'https://twitter.com/intent/tweet?text=I%20just%20revealed%20my%20NFT%20on%20the%20game!%20Check%20it%20out%20here:%20[LINK_TO_NFT]';
        // window.open(url, '_blank', 'noopener,noreferrer');
    };
    
    useEffect(() =>{
        
        const checkIfMobile = () => {
            const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            console.log(isMobileDevice)
            setIsMobile(isMobileDevice);
        };

        const handleOrientationChange = () => {
            const landscape = window.innerWidth > window.innerHeight;
            setIsLandscape(landscape);
            if(isMobile && landscape) {
                setIsMobile(false)
            }
            else{
                checkIfMobile()
            }
        }


        window.addEventListener('resize', handleOrientationChange)
        checkIfMobile()
        handleOrientationChange()


        return () => {
            window.removeEventListener('resize', handleOrientationChange)
        }

    },[isMobile])

    return(
        <>
        {isMobile && !isLandscape && 
            <>
                <Overlay>
                    <ContainerIconTurn>
                        <TurnGif src={phoneGif}/>
                    </ContainerIconTurn>
                </Overlay>
            </>
        }
        
        <ContainerPage>
            <IframeComponent
                    src="https://jeux.ptech.fr/games/presta/revealnft-slot/"
                    onReady={handleReady}
                    onReveal={handleReveal}
                    onMyWallet={handleMyWallet}
                    onShare={handleShare}
                    data= {data}
            />
        </ContainerPage>
        </>
    )

}




export default AnimationComponent