import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3Modal from 'web3modal';
import { useEthers } from '@usedapp/core';
import { useNavigate } from "react-router-dom";
import BackButton from "../assets/Button-Background.png"
import BackLaptop from "../assets/Background-Laptop.png"
import LanguageB from "../assets/language-selector.png"



const ContainerPage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: url(${BackLaptop}) ;
`

const ContainerTop = styled.div`
  display : flex;
  flex-direction row;
  position: absolute;
  top: 0;
  width: 100%
`

const LanguageButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position:absolute;
  right: 0;
  padding: 10px;
`

const ButtonLanguage = styled.button`
  display: flex;
  font-size: 1.3rem;
  border: none;
  color: white;
  background: #e13ae3;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  @media (max-width: 400px) {
    transform: scale(0.7);
  }
`


const ContainerConnect = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 50%;
    overflow: hidden;
    @media (max-width: 720px) {
      width: 100%;
    }
    @media (max-width: 330px) {
      width: 100%;
      height: 70%;
      transform: scale(0.8);
    }

    @media (min-width:721px) and (max-width: 1000px) {
    
    }
`

const ContainerTextConnect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  color: white;
  
`

const HeaderText = styled.h1`
  width: 100%;
  @media (min-width:721px) and (max-width: 1000px) {
    font-size: 2.6rem;
   }
`

const TextConnect = styled.p`
  display: flex;
  width: 100%;
  font-size: 1.2rem;
  @media (max-width: 720px) {
    width: 90%;
    font-size: 1rem;
  }
  @media (min-width:721px) and (max-width: 1000px) {
   font-size: 1.6rem;
   width: 100%;
  }
`

const ConnectButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;
    height: 20%;
    font-size: 2.5rem;
    background: url(${BackButton});
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    border: none;
    font-family: ZombiePunksAlternative !important;
    cursor: pointer;
    @media (max-width: 720px) {
        width: 100%;
        height: 26%;
        transform: scale(0.8);
    }

    @media (max-width: 400px) {
      width: 100%;
      transform: scale(0.7);
    }

    @media (min-width:721px) and (max-width: 1000px) {
        width: 100%;
        height: 13%;
    }
`



function ConnectWallet() {

  const {account,activate, chainId} = useEthers()
  const [hasAccount, setHasAcccount] = useState<boolean>(false)
  const hasNavigated = useRef(false);
  const navigate = useNavigate()

  const [language, setLanguage] = useState<boolean>(true)
  

  const handleLanguageChange = () =>{
      setLanguage(!language)
  }

  useEffect(() =>{
        if(hasAccount && !hasNavigated.current){
            console.log("herre")
            hasNavigated.current = true
            navigate(`/${account}`)
        }
  },[hasAccount])

  const onConnect = async () => {
    try {
      const providerOptions = {
        injected: {
          display: {
            name: 'Metamask',
            description: 'Connect with the provider in your Browser',
          },
          package: null,
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1: "https://eth-mainnet.g.alchemy.com/v2/y_DCCPzdpkPbERL45dUqnYTvgaIHZ7Kc",
              5: "https://eth-goerli.g.alchemy.com/v2/ztdP_BRgJuJUQxXS2U4rnwfiFJzexpQc"
            }
          },
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            rpc: {
              1: "https://eth-mainnet.g.alchemy.com/v2/y_DCCPzdpkPbERL45dUqnYTvgaIHZ7Kc",
              5: "https://eth-goerli.g.alchemy.com/v2/ztdP_BRgJuJUQxXS2U4rnwfiFJzexpQc"
            }
          },
        }
    }

      const web3Modal = new Web3Modal({
        providerOptions,
      });

      const provider = await web3Modal.connect();
      await provider.enable()
      await activate(provider);
      provider.on("accountsChanged", async (accounts: string) => {
        // Do something with the new accounts
        console.log("Accounts changed:", accounts);
        await activate(provider);
      });
      
    } catch (error) {
      console.error(error);
    }
  }

  
  useEffect(()=>{
    if(account){
    console.log(account)
      setHasAcccount(true)
    }
  },[account])
    return(
        <>
        <ContainerTop>
          <LanguageButtonContainer>
            <ButtonLanguage onClick={handleLanguageChange}>
              {language ? 'EN': 'FR'}
            </ButtonLanguage>
          </LanguageButtonContainer>
        </ContainerTop>
        <ContainerPage>
            <ContainerConnect>
              <ContainerTextConnect>
                <HeaderText>
                  {language ? 'REVEAL NOW !'  : `C'EST L'HEURE DU REVEAL !` }
                </HeaderText>
                <TextConnect>
               
                {language ? ` Are you ready to reveal your Joker(s) ? 
                              Let the slot machine guide you, it is full of surprises ! .`  
                              : ` Es-tu prêt à découvrir ton armée de Joker(s) ? 
                              Laisse la machine à sous te guider, elle est pleine de surprises !` }
                </TextConnect>
              </ContainerTextConnect>
                <ConnectButton onClick={onConnect}>
                    Connect Wallet
                </ConnectButton>
            </ContainerConnect>
        </ContainerPage>
        </>
    )
}


export default ConnectWallet;