import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Config, DAppProvider, Mainnet,Goerli } from '@usedapp/core';

const config : Config = {
  networks: [Mainnet,Goerli],
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: "https://eth-mainnet.g.alchemy.com/v2/y_DCCPzdpkPbERL45dUqnYTvgaIHZ7Kc",
    [Goerli.chainId]: "https://eth-goerli.g.alchemy.com/v2/qWI0T-FTwMAHYwQXpyycDbT0_r2dG7cc"
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <DAppProvider config={config}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </DAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
